import { graphql } from 'gatsby';
import React from 'react';

import PageSection from 'ui-kit/page-section/page-section';

import PageLayout from 'components/layouts/page/page.layout';
import PressReleaseDetail from 'components/press-release/details/press-release-details.component';
import { PressReleaseDetailProps } from 'components/press-release/details/press-release-details.props';

const PressReleasePageProvider = ({ data }: { data: GatsbyTypes.PressReleasePageDataQuery }) => {
    //const paragraphs = data.nodePressReleasePage?.relationships?.field_landing_page_content;
    const metaData = data.nodePressReleasePage?.field_meta_tags || {};

    if (!metaData.nodeTitle && data.nodePressReleasePage.title) {
        metaData.nodeTitle = data.nodePressReleasePage?.title;
    }
    const pressReleaseDetailProps: PressReleaseDetailProps = {
        body: data.nodePressReleasePage.body?.processed ? data.nodePressReleasePage.body.processed : '',
        title: data.nodePressReleasePage.title,
        subtitle: data.nodePressReleasePage.field_subtitle,
        publishedDate: data.nodePressReleasePage.field_published_date,
        author: data.nodePressReleasePage.relationships?.field_author?.name
            ? data.nodePressReleasePage.relationships?.field_author?.name
            : undefined,
        tags: data.nodePressReleasePage.relationships?.field_press_release_tags
            ? data.nodePressReleasePage.relationships?.field_press_release_tags
            : []
    };
    return (
        <PageLayout metaData={metaData}>
            <PageSection key={`page_section_1`}>
                <PressReleaseDetail {...pressReleaseDetailProps}></PressReleaseDetail>
            </PageSection>
        </PageLayout>
    );
};

export default PressReleasePageProvider;

export const query = graphql`
    query PressReleasePageData($language: String!, $nid: Int) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        nodePressReleasePage(drupal_internal__nid: { eq: $nid }) {
            drupal_internal__nid
            title
            body {
                processed
            }
            field_subtitle
            field_published_date(formatString: "MMMM DD, YYYY")
            field_meta_tags {
                title
            }
        }
    }
`;
