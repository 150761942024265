import React, { ReactElement } from 'react';
import { Button as ReactButton } from 'react-bootstrap';

export interface PrintButtonProps {
    className?: string;
    variant?: string;
    text?: string | ReactElement;
}

export default function PrintButton({ className, text, variant }: PrintButtonProps) {
    const handleClick = () => {
        window.print();
    };

    return (
        <ReactButton
            className={`d-print-none print-button${className ? ' ' + className : ''}`}
            variant={variant}
            onClick={handleClick}
        >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" fill="white" />
                <path
                    d="M3 16L1.45142 16.0422C1.32977 16.0401 1.21391 15.9898 1.12929 15.9024C1.04466 15.815 0.998184 15.6217 1.00005 15.5V7.14021C0.998184 7.01855 1.04466 6.90112 1.12929 6.8137C1.21391 6.72628 1.32977 6.67601 1.45142 6.67392H21.5486C21.6088 6.67489 21.6683 6.68772 21.7236 6.71168C21.7789 6.73565 21.8289 6.77027 21.8708 6.81357C21.9128 6.85687 21.9457 6.908 21.9679 6.96405C21.99 7.02009 22.0009 7.07995 21.9999 7.14021V15.5712C22.0009 15.6318 21.9899 15.692 21.9675 15.7483C21.9451 15.8047 21.9117 15.856 21.8694 15.8993C21.827 15.9427 21.7765 15.9772 21.7207 16.0009C21.6649 16.0247 21.605 16.0371 21.5444 16.0375L20 16.0422"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                />
                <path
                    d="M5.63123 6.67391V3.46629C5.63123 3.34262 5.68035 3.22402 5.7678 3.13657C5.85525 3.04913 5.97385 3 6.09752 3H16.9005C17.0242 3 17.1428 3.04913 17.2303 3.13657C17.3177 3.22402 17.3668 3.34262 17.3668 3.46629V6.67391"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                />
                <path
                    d="M3.78479 12.5799H5.6313L19.3179 12.5795"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                />
                <path
                    d="M17.2386 12.5795V19.821C17.2386 19.9447 17.1895 20.0633 17.102 20.1507C17.0146 20.2382 16.896 20.2873 16.7723 20.2873H6.32738C6.20371 20.2873 6.0851 20.2382 5.99766 20.1507C5.91021 20.0633 5.86108 19.9447 5.86108 19.821V12.1561"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                />
                <path
                    d="M18.1116 10.3432C18.5226 10.3432 18.8558 10.01 18.8558 9.59897C18.8558 9.18796 18.5226 8.85477 18.1116 8.85477C17.7006 8.85477 17.3674 9.18796 17.3674 9.59897C17.3674 10.01 17.7006 10.3432 18.1116 10.3432Z"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                />
                <path
                    d="M8 15H15"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                />
                <path
                    d="M8 17.4177L11.5 17.4214"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                />
            </svg>
            {text && <span className={`print-button__text flex-stretch`}>{text}</span>}
        </ReactButton>
    );
}
