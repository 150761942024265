import React, { ReactElement } from 'react';

import { Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby';
import sanitizeHtml from 'sanitize-html';

import { PressReleaseDetailProps } from './press-release-details.props';

import '../press-release.style.scss';
import './press-release-details.style.scss';
import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PrintButton from 'ui-kit/print-button/PrintButton';

const PressReleaseDetail = ({
    title,
    subtitle,
    body,
    publishedDate,
    author,
    tags
}: PressReleaseDetailProps): ReactElement => {
    const { t } = useTranslation();

    return (
        <div className="press-release-details">
            <Row>
                <Col xs="12" sm="12" lg={{ span: 8, offset: 2 }}>
                    <div className="mb-4 pb-2">
                        <Link to={'/news-and-insights'}>
                            <ChevronIcon
                                style={{
                                    display: 'inline-block',
                                    position: 'relative',
                                    top: '-0.1rem',
                                    right: '.25rem'
                                }}
                            />
                            <span className="text-uppercase">{t('menus.pages.newsInsights')}</span>
                        </Link>
                    </div>
                    <div className="press-release-details__info-container">
                        <PrintButton className="float-right no-min-width" variant="text"></PrintButton>
                        {publishedDate && <div className="press-release-details__info">{publishedDate}</div>}
                        {author && <div className="press-release-details__info">{author}</div>}
                    </div>
                    <h1 className="h2 my-3 py-1">{title}</h1>
                    {tags.length > 0 && (
                        <div className="press-release__tags mb-4">
                            {tags.map((tag, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {<div className="press-release__tag">{tag.name}</div>}
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    )}
                    {subtitle && <h2 className="h3 text-slate mt-3">{subtitle}</h2>}
                    <div
                        className="press-release-details__body mt-4 pt-3"
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(body, {
                                allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
                                allowedAttributes: {
                                    a: ['id', 'href', 'name', 'rel', 'target', 'data-*'],
                                    img: ['src', 'alt', 'border', 'width', 'height'],
                                    '*': ['class']
                                }
                            })
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default PressReleaseDetail;
